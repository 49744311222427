import React, {
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";

const InputFile = forwardRef(
  ({ id,title, showToast, handleFileChangeIn }, ref) => {
    const [fileName, setFileName] = useState("");
    const fileInputRef = useRef();

    useEffect(() => {
      setFileName(""); // Limpiar el nombre del archivo al cargar
      if (fileInputRef.current) {
        fileInputRef.current.value = ""; // Limpiar el input de archivo
      }
    }, []);

    const truncateFileName = (name, maxLength = 20) => {
      if (name.length <= maxLength) return name;
      const extension = name.split(".").pop();
      const nameWithoutExtension = name.slice(0, -(extension.length + 1));
      const truncatedName =
        nameWithoutExtension.slice(0, maxLength - extension.length - 3) + "...";
      return `${truncatedName}.${extension}`;
    };

    const handleFileChange = (event) => {
      if (event.target.files.length > 0) {
        const file = event.target.files[0];
        if (file.type === "application/pdf") {
          setFileName(truncateFileName(file.name));
          handleFileChangeIn(id, file);
        } else {
          showToast("PDF_FAIL");
        }
      }
    };

    useImperativeHandle(ref, () => ({
      resetFileInput: () => {
        setFileName("");
        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      },
    }));

    return (
      <div className="w-full py-4 bg-primary-50 rounded-2xl border border-primary-800 gap-3 grid border-dashed">
        <div className="grid gap-1">
          <h2 className="text-center text-gray-400 text-xs leading-4">
            Solo PDF, Tamaño maximo 10MB
          </h2>
        </div>
        <div className="grid gap-2">
          <h4
            className={
              fileName
                ? "text-center text-primary-800 text-[10px] font-normal"
                : "text-center text-primary-800 text-sm font-medium leading-snug"
            }
          >
            {fileName ? fileName : `Cargar ${title}`}
          </h4>
          <div className="flex items-center justify-center">
            <label>
              <input
                ref={fileInputRef}
                type="file"
                hidden
                onChange={handleFileChange}
              />
              <div
                className={`flex w-28 h-9 px-2 flex-col rounded-full shadow text-white text-xs font-semibold leading-4 items-center justify-center cursor-pointer focus:outline-none ${
                  fileName ? "bg-green-500" : "bg-primary-100"
                }`}
              >
                {fileName ? (
                  "Listo"
                ) : (
                  <div>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#02518c"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9 2.22117V7H4.22117C4.31517 6.81709 4.43766 6.64812 4.58579 6.5L8.5 2.58579C8.64812 2.43766 8.81709 2.31517 9 2.22117ZM11 2V7C11 8.10457 10.1046 9 9 9H4C2.89543 9 2 9.89543 2 11V18C2 19.1046 2.89543 20 4 20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20C21.1046 20 22 19.1046 22 18V11C22 9.89543 21.1046 9 20 9V4C20 2.89543 19.1046 2 18 2H11ZM5 11C4.44771 11 4 11.4477 4 12V17C4 17.5523 4.44771 18 5 18C5.55228 18 6 17.5523 6 17V16H6.5C7.16304 16 7.79892 15.7366 8.26777 15.2678C8.73661 14.7989 9 14.163 9 13.5C9 12.837 8.73661 12.2011 8.26777 11.7322C7.79892 11.2634 7.16304 11 6.5 11H5ZM6.5 14H6V13H6.5C6.63261 13 6.75978 13.0527 6.85355 13.1464C6.94732 13.2402 7 13.3674 7 13.5C7 13.6326 6.94732 13.7598 6.85355 13.8536C6.75978 13.9473 6.63261 14 6.5 14ZM11 11C10.4477 11 10 11.4477 10 12V17C10 17.5523 10.4477 18 11 18H12.3756C13.0715 17.9996 13.7388 17.7229 14.2309 17.2309C14.7229 16.7388 14.9996 16.0709 15 15.375V13.6244C14.9996 12.9285 14.7229 12.2612 14.2309 11.7691C13.7388 11.2771 13.0709 11.0004 12.375 11H11ZM12 16V13H12.3746C12.5404 13.0002 12.6994 13.0661 12.8166 13.1834C12.934 13.3007 12.9999 13.4597 13 13.6256V15.3744C12.9999 15.5403 12.934 15.6993 12.8166 15.8166C12.6993 15.934 12.5403 15.9999 12.3744 16H12ZM17 11C16.4477 11 16 11.4477 16 12V17C16 17.5523 16.4477 18 17 18C17.5523 18 18 17.5523 18 17V16H19C19.5523 16 20 15.5523 20 15C20 14.4477 19.5523 14 19 14H18V13H19C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11H17Z"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </label>
          </div>
        </div>
      </div>
    );
  }
);

export default InputFile;
