import React from "react";
import { Link } from "react-router-dom";

export default function CardExpe_Carpeta_User({
  status,
  date,
  icons,
  link,
  title,
}) {
  const titleClass = title.length > 19 ? "text-[10px]" : "text-[10px]";
  const borderColorClass =
    status === "ACTIVO" ? "border-primary-800" : "border-red-500";
  return (
    <>
      <Link to={link}>
        <div
          className={`lg:w-[80%] lg:h-[200px] smm:w-[100%] smm:mb-2 bg-primary-50 border-4 ${borderColorClass} rounded-lg`}
        >
          <div className="w-full absolute lg:ml-0 smm:ml-64">
            <p className="pt-2 px-4 text-[10px] text-red-500">
              {status == "ACTIVO" ? "" : "Fecha retiro: " + date}
            </p>
          </div>
          <div
            className={`lg:w-[100%] lg:h-[200px] smm:w-[100%] smm:mb-2 smm:flex lg:flex-col lg:content-center lg:justify-center lg:items-center `}
          >
            <div className="lg:flex lg:justify-center p-2 lg:w-full smm:w-4/12 ">
              <div className="lg:flex smm:flex smm:justify-center smm:items-center">
                <svg
                  className={`w-[50%] ${
                    status == "ACTIVO" ? "text-primary-800" : "text-red-500"
                  }`}
                  viewBox="0 0 40 40"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_77_35)">
                    <path
                      d="M20 0C9 0 0 9 0 20C0 31 9 40 20 40C31 40 40 31 40 20C40 9 31 0 20 0ZM20 6C23.4 6 26 8.6 26 12C26 15.4 23.4 18 20 18C16.6 18 14 15.4 14 12C14 8.6 16.6 6 20 6ZM20 34.4C15 34.4 10.6 31.8001 8 28C8 24 16 21.8 20 21.8C24 21.8 32 24 32 28C29.4 31.8 25 34.4 20 34.4Z"
                      fill="currentColor"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_77_35">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="smm:w-8/12 lg:text-center  lg:px-1 smm:flex smm:justify-center smm:items-center lg:mb-4">
              <span
                className={`${
                  status == "ACTIVO" ? "text-primary-800" : "text-red-500"
                } font-bold ${titleClass}`}
              >
                {title}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </>
  );
}
