import React from "react";

export default function ModalCheckExpe({isOpen, onClose, chec_expe}){

    
    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
            <div className="modal-content lg:w-[50%] smm:w-[90%]">
                <div className='px-2 border-b-4 border-primary-800'>
                    <h2 className='mb-2 text-xl font-semibold text-primary-800'>EXPEDIENTE REVISADO</h2>
                </div>
                <form className="w-full mx-auto">
                    <div className='grid grid-cols-1 mt-4'>
                        <div>
                            <label for="EXPE" className="block lg:mb-0.5 smm:mb-0 text-sm font-medium text-primary-900">EXPEDIENTE:  {chec_expe.cod_exp}</label>
                        </div>
                        <div className="col-span-1">
                            <label for="ARAZON" className="block lg:mb-0.5 smm:mb-0 text-sm font-medium text-primary-900">SOCIO: {chec_expe.pertenece}</label>
                        </div>
                        <div className="col-span-1">
                            <label for="revisa" className="block lg:mb-0.5 smm:mb-0 text-sm font-medium text-primary-900">REVISADO POR: {chec_expe.revisa}</label>
                        </div>
                    </div>

                        <div className="relative overflow-x-auto my-4 shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr className="bg-primary-800 text-primary-50">
                                        <th scope="col" className="p-4">
                                            N°
                                        </th>
                                        <th scope="col" className="px-2 py-3">DESCRIPCIÓN</th>
                                        <th scope="col" className="px-6 py-3">VALORACIÓN</th>

                                    </tr>
                                </thead>
                                <tbody>
                                
                                {chec_expe.data.map((check)=>(

                                
                                        <tr key={check.cod_valor}  className="bg-white border-b border-primary-800 hover:bg-primary-100">
                                            
                                            <td className="px-4 py-1 text-primary-800">{check.cod_valor}</td>
                                            <td className="px-2 py-1 text-primary-800">{check.descripcion}</td>
                                            <td className="px-2 py-1 text-primary-800 font-bold">{check.des_valor}</td>
                                                                            
                                        </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                        <div className="mb-2">
                            <label className="text-primary-800 font-bold">COMENTARIO</label>
                            <div className="h-32 mt-2 border-2 border-primary-800 rounded-lg">
                                <p className="px-4 text-primary-800">{chec_expe.comentario}</p>
                            </div>
                            
                        </div>
                    
                    <div className='flex justify-end p-2 border-t-2 border-primary-800 gap-4'>
                        <button type="button" onClick={onClose} className='bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500'>Cerrar</button>
                    </div>
                </form>
            </div>
        </div>
    );
}