import React, { useState,useContext } from "react";
import InputImageCamera from "../InputImageCamera";
import { UserContext } from '../../UserContext.js';

export default function ModalGenerarReportUbicacion({ isOpen, onClose }) {
    const { userData, setUserData } = useContext(UserContext);
    const [socioReport,setSocioReport]=useState('');
    const [ isSocio,setIsSocio]=useState('');
    const initialFormData={DNI:'', SOCIO:''}
    
    const [formData, setFormData] = useState(initialFormData);

    const handleChange = (e) => {
        const { id, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [id.toUpperCase()]: value
        }));
    };

    const handleGenerarReporte = async (e) => {
        e.preventDefault();
        try {
            const userAgent = navigator.userAgent;
            console.log(userAgent);
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/genera_pdf_gps', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "true",
                    "User-Agent": userAgent,
                    "Content-Type": "application/json" // Añadir el tipo de contenido
                }),
                body: JSON.stringify({ user: userData.DNI, dni_socio: formData.DNI })
            });
    
            if (!response.ok) {
                // Manejo de error HTTP
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json(); // Asumiendo que la respuesta es un JSON
            window.open('https://ram-special-evenly.ngrok-free.app/api-mongo'+data.url);
    
        } catch (error) {
            alert(`Error al generar el reporte: ${error.message}`);
            console.error('Error al generar el reporte:', error);
        }
    };
    

    const fetchSocio = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://ram-special-evenly.ngrok-free.app/api-mongo/api/get_verifica_gps', {
                method: 'POST',
                headers: new Headers({
                    "ngrok-skip-browser-warning": "69420",
                }),
                body: JSON.stringify({user:userData.DNI,dni_socio:formData.DNI})
            });
            const socioData = await response.json();
            if (socioData.status===true) {
                setIsSocio(socioData.status);
                setSocioReport(socioData.data);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    SOCIO: socioData.socio
                }));
            }else{
                setIsSocio(socioData.status);
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    SOCIO: "EL DNI INGRESADO NO EXISTE EN NUESTRA BD"
                }));
            }
        } catch (error) {
            console.error('Error fetching socio:', error);
        }
    };

    if (!isOpen) return null;
    return (
        <>
            <div className="modal-overlay">
                <div className="modal-content lg:w-[50%] smm:w-[90%] max-h-[90vh] overflow-y-auto">
                    <div className="flex justify-end">
                        <button onClick={onClose} className="text-primary-800">
                            <svg
                                className="w-8 h-8"
                                aria-hidden="true"
                                fill="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM9.70711 8.29289C9.31658 7.90237 8.68342 7.90237 8.29289 8.29289C7.90237 8.68342 7.90237 9.31658 8.29289 9.70711L10.5858 12L8.29289 14.2929C7.90237 14.6834 7.90237 15.3166 8.29289 15.7071C8.68342 16.0976 9.31658 16.0976 9.70711 15.7071L12 13.4142L14.2929 15.7071C14.6834 16.0976 15.3166 16.0976 15.7071 15.7071C16.0976 15.3166 16.0976 14.6834 15.7071 14.2929L13.4142 12L15.7071 9.70711C16.0976 9.31658 16.0976 8.68342 15.7071 8.29289C15.3166 7.90237 14.6834 7.90237 14.2929 8.29289L12 10.5858L9.70711 8.29289Z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="px-2 border-b-2 border-primary-800">
                        <h2 className="mb-2 lg:text-xl smm:text-sm font-semibold text-primary-800 uppercase">
                            GENERAR REPORTE DE UBICACIÓN
                        </h2>
                    </div>
                    <form className="w-full mx-auto" onSubmit={handleGenerarReporte} > 
                        <div className="mt-2 grid lg:grid-cols-3 sm:grid-cols-1 gap-2">
                            <div className="w-full">
                                <label
                                    htmlFor="dni"
                                    className="block mb-0 text-sm font-medium text-primary-900"
                                >
                                    DNI
                                </label>
                                <div className="inline-flex w-full">
                                    <input
                                        type="number"
                                        id="DNI"
                                        value={formData.DNI}
                                        onChange={handleChange}
                                        className="w-10/12 shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-sm rounded-s-lg focus:ring-primary-800 focus:border-primary-800 block p-2.5"
                                        placeholder="DNI"
                                    />
                                    <button
                                    onClick={fetchSocio} 
                                    className="bg-primary-800 w-2/12 rounded-e-lg">
                                        <svg 
                                            xmlns="http://www.w3.org/2000/svg" 
                                            className="h-[90%] w-[90%] border-r border-transparent stroke-primary-50 peer-focus:border-primary-800 peer-focus:stroke-primary-800" 
                                            fill="none" 
                                            viewBox="0 0 24 24" 
                                            stroke="currentColor" 
                                            strokeWidth="2"
                                        >
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="col-span-2 mb-2">
                                <label
                                    htmlFor="dni"
                                    className="block mb-0 text-sm font-medium text-primary-900"
                                >
                                    SOCIO
                                </label>
                                <input
                                    disabled
                                    value={formData.SOCIO}
                                    onChange={handleChange}
                                    type="text"
                                    id="SOCIO"
                                    className="shadow-sm bg-primary-50 border border-primary-800 text-primary-900 text-[12px] rounded-lg focus:ring-primary-800 focus:border-primary-800 block w-full p-2.5"
                                    placeholder="SOCIO"
                                />
                            </div>
                            {formData.SOCIO&&(<>
                            {isSocio===false?(
                                <div className="col-span-3 grid justify-items-center mb-2">
                                    <h1 className="text-primary-800 font-bold">NO SE ENCONTRO INFORMACIÓN</h1>
                                </div>
                            ):(
                                <div className="col-span-3 grid justify-items-center mb-2">
                                    <h1 className="text-primary-800 font-bold">REPORTES DISPONIBLES</h1>
                                    <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-2 justify-items-center">
                                    {socioReport && socioReport.map((item) => (
                                        <div className="w-full p-2 border-2 rounded-xl border-primary-800">
                                            <p className="text-primary-800 font-bold">{item.tipo_ubicacion}</p>
                                            <p className="text-primary-800 font-bold">Suministro: <span className="font-normal">{item.suministro}</span></p>
                                            <p className="text-primary-800 font-bold">Dirección: <span className="font-normal">{item.direccion}</span></p>
                                            <p className="text-primary-800 font-bold">Coordenadas: <span className="font-normal">{item.lat},{item.lng}</span></p>
                                            <p className="text-primary-800 font-bold">Fecha: <span className="font-normal">{item.fecha_cap}</span></p>
                                            <p className="text-primary-800 font-bold">Hora: <span className="font-normal">{item.hora_cap}</span></p>
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            )}
                            </>)}
                        </div>

                    <div className="flex justify-end p-2 border-t-2 border-primary-800 gap-4">
                        <button type="submit" className="bg-primary-700 text-primary-50 rounded border-2 border-primary-800 p-2.5 hover:bg-primary-50 hover:text-primary-800">Generar</button>
                        <button type="button" onClick={onClose} className="bg-red-500 text-primary-50 rounded border-2 border-red-500 p-2.5 ml-2 hover:bg-red-50 hover:text-red-500">Cerrar</button>
                    </div>
                    </form>
                </div>
            </div>
        </>
    );
}
