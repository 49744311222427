import React, { useEffect, useContext, useState } from "react";
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import { UserContext } from "../UserContext.js";
import { Link } from "react-router-dom";
import Loader from "../common/Loader.jsx";
import ModaSinRegistros from "../common/Modal/ModalSinRegistros.jsx";

export default function RevisarSolicitudes() {
  const { userData, setUserData } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [Error, setError] = useState();
  const [solicitud, setSolicitud] = useState([]);
  const [urlFirm, setUrlFirm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isModalSinRegistroOpen, setIsModalSinRegistroOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const fetchData = async () => {
    try {
      if (userData.DEPARTAMENTO === "AUDITORIA INTERNA") {
        const response = await fetch(
          "https://ram-special-evenly.ngrok-free.app/api-mongo/api/revisar-solicitud",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user: userData.DNI }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (!result.data || !Array.isArray(result.data)) {
          throw new Error("Invalid data format received");
        }
        setSolicitud(result.data); // Actualizar el estado con los datos recibidos
        setLoading(false);
      } else if (userData.CARGO === "ADMINISTRADOR") {
        const response = await fetch(
          "https://ram-special-evenly.ngrok-free.app/api-mongo/api/revisar-solicitud",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user: userData.DNI }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (!result.data || !Array.isArray(result.data)) {
          throw new Error("Invalid data format received");
        }
        setSolicitud(result.data); // Actualizar el estado con los datos recibidos
        setLoading(false);
      } else {
        const response = await fetch(
          "https://ram-special-evenly.ngrok-free.app/api-mongo/api/revisar-solicitud-dti",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user: userData.DNI }),
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const result = await response.json();
        if (result.status == true) {
          setSolicitud(result.data); // Actualizar el estado con los datos recibidos
          setLoading(false);
        } else {
          setLoading(false);
          setIsModalSinRegistroOpen(true);
          setErrorMessage("SIN REGISTRO");
        }
      }
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
      setLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleverSolicitud = async (enlace) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/firmar_expe",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ url: enlace, DNI: userData.DNI }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setUrlFirm(result);
      window.open(result);
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
    }
  };

  const handleAprobarSolicitud = async (cod_sol, revisado, cod_expe) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/dar-atendido-solicitud",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user: userData.DNI,
            cod_sol: cod_sol,
            revisado: revisado,
            cod_expe: cod_expe,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setIsModalSinRegistroOpen(true);
      setErrorMessage(result.message);
      fetchData();
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
    }
  };
  const handleEjecutarSolicitud = async (cod_sol, revisado) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/dar-ejecutar-solicitud",
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            user: userData.CARGO,
            cod_sol: cod_sol,
            revisado: revisado,
          }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      fetchData();
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <SidebarExpedientes />
      <div className="p-4 sm:ml-64">
        <h1 className="font-light text-primary-800 m-2 text-xl">
          <span className="font-bold">Mis Solicitudes</span>
        </h1>
        <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg"></div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr className="bg-primary-800 text-primary-50">
                <th scope="col" className="p-4">
                  N°
                </th>
                <th scope="col" className="px-2 py-3">
                  COD. SOLICITUD
                </th>
                <th scope="col" className="px-2 py-3">
                  COD. EXPEDIENTE
                </th>
                <th scope="col" className="px-2 py-3">
                  MOTIVO
                </th>
                <th scope="col" className="px-6 py-3">
                  FECHA INGRESO
                </th>
                <th scope="col" className="px-6 py-3">
                  FECHA RESPUESTA
                </th>
                <th scope="col" className="px-6 py-3">
                  CONDICIÓN
                </th>
                <th scope="col" className="px-6 py-3">
                  OPCIONES
                </th>
              </tr>
            </thead>
            <tbody>
              {solicitud &&
                solicitud.map((solicitud) => (
                  <tr
                    key={solicitud.num}
                    className="bg-white border-b hover:bg-primary-100"
                  >
                    <td className="w-4 p-4">{solicitud.num}</td>
                    <th
                      scope="row"
                      className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap"
                    >
                      {solicitud.name}
                    </th>
                    <td className="px-2 py-4">{solicitud.cod_expe}</td>
                    <td className="px-2 py-4">{solicitud.asunto}</td>

                    <td className="px-2 py-4">{solicitud.fecha_in}</td>
                    <td className="px-2 py-4">{solicitud.fecha_revisa}</td>
                    <td className="px-2 py-4">{solicitud.condicion}</td>
                    <td className="px-2 py-4">
                      <div className="grid grid-cols-4">
                        <button
                          onClick={() => handleverSolicitud(solicitud.pdf_link)}
                          className=" w-[25px] tooltip"
                        >
                          <svg
                            width="25"
                            height="21"
                            viewBox="0 0 25 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10.9501 16.2167C6.08343 16.2167 2.2001 13.3333 0.350098 8.98333C2.21676 4.63333 6.1001 1.75 10.9501 1.75C15.8001 1.75 19.7001 4.63333 21.5668 8.98333C19.7001 13.3333 15.8001 16.2167 10.9501 16.2167ZM10.9501 14.4833C14.8001 14.4833 18.1168 12.2333 19.6668 8.98333C18.1168 5.73333 14.8001 3.48333 10.9501 3.48333C7.11676 3.48333 3.8001 5.73333 2.2501 8.98333C3.78343 12.2333 7.1001 14.4833 10.9501 14.4833ZM6.91676 8.98333C6.91676 6.75 8.71676 4.95 10.9334 4.95C13.1668 4.95 14.9668 6.76667 14.9668 8.98333C14.9668 11.2167 13.1501 13.0167 10.9334 13.0167C8.71676 13.0167 6.91676 11.2 6.91676 8.98333ZM11.1834 7.96667C11.1834 8.66667 11.7334 9.23333 12.4501 9.23333C13.1334 9.23333 13.7001 8.66667 13.7001 7.96667C13.7001 7.28333 13.1501 6.71667 12.4501 6.71667C11.7501 6.71667 11.1834 7.26667 11.1834 7.96667Z"
                              fill="#02518C"
                            />
                          </svg>
                          <span className="tooltiptextPrimary">Visualizar</span>
                        </button>
                        {userData.DEPARTAMENTO === "AUDITORIA INTERNA" ? (
                          <button
                            className=" w-[25px] tooltip"
                            disabled={
                              solicitud.revisado === "Pendiente" ? true : false
                            }
                            onClick={() =>
                              handleAprobarSolicitud(
                                solicitud.name,
                                solicitud.condicion,
                                solicitud.cod_expe
                              )
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 7V2.22117C8.81709 2.31517 8.64812 2.43766 8.5 2.58579L4.58579 6.5C4.43766 6.64812 4.31517 6.81709 4.22117 7H9Z"
                                fill="#383838"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 7V2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V9H9C10.1046 9 11 8.10457 11 7ZM15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929C15.3166 10.9024 14.6834 10.9024 14.2929 11.2929L11 14.5858L9.70711 13.2929C9.31658 12.9024 8.68342 12.9024 8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L15.7071 12.7071Z"
                                fill="#02518C"
                              />
                            </svg>
                            <span className="tooltiptextPrimary">Ejecutar</span>
                          </button>
                        ) : userData.DEPARTAMENTO === "DPTO.DE CREDITOS" ? (
                          <button
                            className=" w-[25px] tooltip"
                            disabled={
                              solicitud.revisado === "Pendiente" ? true : false
                            }
                            onClick={() =>
                              handleAprobarSolicitud(
                                solicitud.name,
                                solicitud.condicion,
                                solicitud.cod_expe
                              )
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9 7V2.22117C8.81709 2.31517 8.64812 2.43766 8.5 2.58579L4.58579 6.5C4.43766 6.64812 4.31517 6.81709 4.22117 7H9Z"
                                fill="#383838"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11 7V2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V9H9C10.1046 9 11 8.10457 11 7ZM15.7071 12.7071C16.0976 12.3166 16.0976 11.6834 15.7071 11.2929C15.3166 10.9024 14.6834 10.9024 14.2929 11.2929L11 14.5858L9.70711 13.2929C9.31658 12.9024 8.68342 12.9024 8.29289 13.2929C7.90237 13.6834 7.90237 14.3166 8.29289 14.7071L10.2929 16.7071C10.6834 17.0976 11.3166 17.0976 11.7071 16.7071L15.7071 12.7071Z"
                                fill="#02518C"
                              />
                            </svg>
                            {solicitud.condicion === "Aprobado" ? (
                              <span className="tooltiptextPrimary">
                                Bloqueado
                              </span>
                            ) : (
                              <span className="tooltiptextPrimary">
                                Ejecutar
                              </span>
                            )}
                          </button>
                        ) : (
                          <button
                            disabled={
                              solicitud.revisado === "Aprobado" ? true : false
                            }
                            onClick={() =>
                              handleEjecutarSolicitud(
                                solicitud.name,
                                solicitud.condicion
                              )
                            }
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12ZM15.7071 10.7071C16.0976 10.3166 16.0976 9.68342 15.7071 9.29289C15.3166 8.90237 14.6834 8.90237 14.2929 9.29289L11 12.5858L9.20711 10.7929C8.81658 10.4024 8.18342 10.4024 7.79289 10.7929C7.40237 11.1834 7.40237 11.8166 7.79289 12.2071L10.2929 14.7071C10.6834 15.0976 11.3166 15.0976 11.7071 14.7071L15.7071 10.7071Z"
                                fill="#20A61B"
                              />
                            </svg>
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <ModaSinRegistros
          isOpen={isModalSinRegistroOpen}
          onClose={() => setIsModalSinRegistroOpen(false)}
          message={errorMessage}
        />
      </div>
    </>
  );
}
