import React, { useEffect, useState, useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Navbar from "../navbar/navBar";
import SidebarExpedientes from "../sideBar/SideBarExpedientes";
import { UserContext } from "../UserContext.js";
import ModalAddExpe from "../common/Modal/ModalAddExpe.jsx";
import ModalSolicitud from "../common/Modal/ModalSolicitud.jsx";
import ModalCheckExpe from "../common/Modal/ModalCheckExpe.jsx";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../common/Loader.jsx";
import ModaSinRegistros from "../common/Modal/ModalSinRegistros.jsx";
import Modal_ver_pdf from "../common/Modal/Modal_ver_pdf.jsx";

export default function MisExpediente_cola_mes_selected() {
  const { anio, mes } = useParams();
  const { userData, setUserData } = useContext(UserContext);
  const [Error, setError] = useState();
  const [expe, setExpe] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 10;
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenSolicitud, setIsModalOpenSolicitud] = useState(false);
  const [isModalCheckExpe, setIsModalCheckExpe] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedExpe, setSelectedExpe] = useState(null);
  const [selectedCheckExpe, setSelectedCheckExpe] = useState(null);
  const [toastMessage, setToastMessage] = useState("");
  const [urlFirm, setUrlFirm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isModalSinRegistroOpen, setIsModalSinRegistroOpen] = useState(false); // Estado para manejar la visibilidad del modal de error
  const [errorMessage, setErrorMessage] = useState("");

  const [modalIsOpenPDF, setModalIsOpenPDF] = useState(false);
  const [urlPdf, setUrlPdf] = useState(""); // Reemplaza con la URL de tu PDF

  const openModalPDF = async (url) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/firmar_expe",
        {
          method: "POST",
          headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
          }),
          body: JSON.stringify({ url: url, DNI: userData.DNI }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setUrlPdf(result);
      setModalIsOpenPDF(true);
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
    }
  };

  const handlecloseModalPDF = () => {
    setModalIsOpenPDF(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedUser(null);
  };

  const handleCloseModalCheckExpe = () => {
    setIsModalCheckExpe(false);
    setSelectedExpe(null);
  };

  const handleCloseModalSoli = () => {
    setIsModalOpenSolicitud(false);
    setSelectedExpe(null);
  };

  const showToast = (message) => {
    setToastMessage(message);
    if (message == "existe") {
      toast.success("El expediente se proceso exitosamente");
      fetchData();
    } else if (message == "add_solicitud") {
      toast.success("Su solicitud fue enviada");
      fetchData();
    } else if (message == "error_permisos") {
      toast.error("Error: por permisos");
    } else if (message == "NULL_ARCHIVOS") {
      toast.error("ES NECESARIO CARGAR ARCHIVOS");
    } else {
      toast.error("Error al agregar el expediente");
    }
  };

  const handleCheckExpe = async (expe) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/check_exp_cola",
        {
          method: "POST",
          headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
          }),
          body: JSON.stringify({ EXPE: expe }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setIsModalCheckExpe(true);
      setSelectedCheckExpe(result);
    } catch (error) {
      setError(error);
      console.log(Error); // Actualizar el estado con el error si ocurre
    }
  };

  const handleAddSolicitud = (expe_cod) => {
    setIsModalOpenSolicitud(true);
    setSelectedExpe(expe_cod);
  };

  const handleAddExpe = () => {
    setIsModalOpen(true);
  };

  const handleverExpe = async (enlace) => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/firmar_expe",
        {
          method: "POST",
          headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
          }),
          body: JSON.stringify({ url: enlace, DNI: userData.DNI }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      setUrlFirm(result);
      window.open(result);
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(
        "https://ram-special-evenly.ngrok-free.app/api-mongo/api/mis-expedientes",
        {
          method: "POST",
          headers: new Headers({
            "ngrok-skip-browser-warning": "69420",
          }),
          body: JSON.stringify({ user: userData.DNI, anio: anio, mes: mes }),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      if (!result.data || !Array.isArray(result.data)) {
        throw new Error("Invalid data format received");
      }
      setExpe(result.data); // Actualizar el estado con los datos recibidos
      setLoading(false);
    } catch (error) {
      setError(error); // Actualizar el estado con el error si ocurre
      setLoading(false);
      setIsModalSinRegistroOpen(true); // Mostrar el modal de error
      setErrorMessage("REGISTRO VACIO");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userData && userData.DNI) {
      fetchData();
    }
  }, [userData, anio, mes]);
  // Calcular los índices de los usuarios a mostrar en la página actual
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentExpe = expe.slice(indexOfFirstUser, indexOfLastUser);
  // Función para cambiar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <>
      {isLoading && <Loader />}
      <Navbar />
      <SidebarExpedientes />
      {!isLoading && (
        <>
          <div className="p-4 sm:ml-64">
            <h1 className="font-light text-primary-800 m-2 text-sm">
              {`Mis Expedientes > ${anio} > `}
              <span className="font-bold text-xl">{mes}</span>
            </h1>
            <div className="p-4 border-2 grid grid-cols-1 gap-4 border-gray-200 border-dashed rounded-lg">
              <div className="inline-flex justify-between mb-4">
                <div className="bg-secondary-200 rounded-lg">
                  <button
                    onClick={() => handleAddExpe()}
                    className="flex items-center p-2 text-primary-800 rounded-lg hover:bg-secondary-400 group"
                  >
                    <img
                      src={`${process.env.PUBLIC_URL}${"/icons/icons_add.svg"}`}
                      loading="lazy"
                      className="w-6 h-6"
                    />
                    <span className="flex-1 ms-3 whitespace-nowrap">Nuevo</span>
                  </button>
                </div>
              </div>

              <div className="smm:hidden lg:flex lg:relative lg:overflow-x-auto lg:shadow-md sm:rounded-lg">
                <table className="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr className="bg-primary-800 text-primary-50">
                      <th scope="col" className="p-4">
                        N°
                      </th>
                      <th scope="col" className="px-2 py-3">
                        APELLIDOS Y NOMBRE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        DNI
                      </th>
                      <th scope="col" className="px-6 py-3">
                        COD_EXPEDIENTE
                      </th>
                      <th scope="col" className="px-6 py-3">
                        CONDICIÓN
                      </th>
                      <th scope="col" className="px-6 py-3">
                        FECHA INGRESO
                      </th>
                      <th scope="col" className="px-6 py-3">
                        FECHA REVISIÓN
                      </th>
                      <th scope="col" className="px-6 py-3">
                        OPCIONES
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentExpe.map((expe) => (
                      <tr
                        key={expe.cod_exp}
                        className="bg-white border-b hover:bg-primary-100"
                      >
                        <td className="w-4 p-4">{expe.num}</td>
                        <th
                          scope="row"
                          className="px-2 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {expe.nom_ape}
                        </th>
                        <td className="px-2 py-4">{expe.dni_socio}</td>
                        <td className="px-2 py-4">{expe.cod_exp}</td>
                        <td className="px-2 py-4">{expe.condicion}</td>
                        <td className="px-2 py-4">{expe.fecha_in}</td>
                        <td className="px-2 py-4">{expe.fecha_re}</td>
                        <td className="w-[200px] px-6 py-4">
                          <div className="grid grid-cols-4">
                            <button
                              onClick={() => handleverExpe(expe.enlace)}
                              className=" w-[25px]"
                            >
                              <svg
                                width="25"
                                height="21"
                                viewBox="0 0 25 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.9501 16.2167C6.08343 16.2167 2.2001 13.3333 0.350098 8.98333C2.21676 4.63333 6.1001 1.75 10.9501 1.75C15.8001 1.75 19.7001 4.63333 21.5668 8.98333C19.7001 13.3333 15.8001 16.2167 10.9501 16.2167ZM10.9501 14.4833C14.8001 14.4833 18.1168 12.2333 19.6668 8.98333C18.1168 5.73333 14.8001 3.48333 10.9501 3.48333C7.11676 3.48333 3.8001 5.73333 2.2501 8.98333C3.78343 12.2333 7.1001 14.4833 10.9501 14.4833ZM6.91676 8.98333C6.91676 6.75 8.71676 4.95 10.9334 4.95C13.1668 4.95 14.9668 6.76667 14.9668 8.98333C14.9668 11.2167 13.1501 13.0167 10.9334 13.0167C8.71676 13.0167 6.91676 11.2 6.91676 8.98333ZM11.1834 7.96667C11.1834 8.66667 11.7334 9.23333 12.4501 9.23333C13.1334 9.23333 13.7001 8.66667 13.7001 7.96667C13.7001 7.28333 13.1501 6.71667 12.4501 6.71667C11.7501 6.71667 11.1834 7.26667 11.1834 7.96667Z"
                                  fill="#02518C"
                                />
                              </svg>
                            </button>
                            <button
                              onClick={() => handleAddSolicitud(expe.cod_exp)}
                              className="w-[25px]"
                            >
                              <svg
                                width="16"
                                height="23"
                                viewBox="0 0 16 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.2 6.57143V18.4898C13.2 19.0449 12.7429 19.502 12.1878 19.502H4.33469C3.77959 19.502 3.32245 19.0449 3.32245 18.4898V6.57143H13.2ZM10.6367 7.89388V18.1469H11.2245V7.89388H10.6367ZM7.97551 7.89388V18.1469H8.56327V7.89388H7.97551ZM5.31429 7.89388V18.1469H5.90204V7.89388H5.31429ZM5.96735 3.81225V2.96327C5.96735 2.42449 6.39184 2 6.93061 2H9.60816C10.1469 2 10.5714 2.42449 10.5714 2.96327V3.81225H13.5265C14.0816 3.81225 14.5388 4.26939 14.5388 4.82449V5.52653H2V4.80816C2 4.25306 2.45714 3.79592 3.01224 3.79592H5.96735V3.81225ZM6.93061 2.65306C6.76735 2.65306 6.63673 2.78367 6.63673 2.94694V3.79592H9.91837V2.94694C9.91837 2.78367 9.78776 2.65306 9.62449 2.65306H6.93061Z"
                                  fill="#02518C"
                                />
                              </svg>
                            </button>
                            <button
                              disabled={
                                expe.condicion !== "Ingresado" ? false : true
                              }
                              onClick={() => handleCheckExpe(expe.cod_exp)}
                              className="w-[25px]"
                            >
                              <img
                                src={`${process.env.PUBLIC_URL}/icons/icons_check_expediente.svg`}
                                loading="lazy"
                                className="w-6 h-6 text-primary-800"
                              />
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/**VISTA DESDE UN CELULAR CAMBIO LA TABLA POR CARD-EXPLORATION */}
              {currentExpe.map((expe) => (
                <div
                  key={expe.num}
                  className="lg:hidden flex bg-primary-50 border-2 border-primary-800 rounded-lg shadow-md shadow-primary-800"
                >
                  <div className="w-2/12 inline-block content-center justify-center items-center">
                    <button
                      onClick={() => openModalPDF(expe.enlace)}
                      className="text-primary-800 w-full "
                    >
                      <svg
                        className="w-full"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill="#02518C"
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M9 7V2.22117C8.81709 2.31517 8.64812 2.43766 8.5 2.58579L4.58579 6.5C4.43766 6.64812 4.31517 6.81709 4.22117 7H9ZM11 7V2H18C19.1046 2 20 2.89543 20 4V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V9H9C10.1046 9 11 8.10457 11 7ZM11.5 12C10.6716 12 10 12.6716 10 13.5C10 14.3284 10.6716 15 11.5 15C11.832 15 12.1389 14.8921 12.3874 14.7095C12.3959 14.7029 12.4044 14.6965 12.4131 14.6902C12.77 14.416 13 13.9849 13 13.5C13 12.6716 12.3284 12 11.5 12ZM11.5 17C11.9693 17 12.417 16.9076 12.8259 16.7401L14.7929 18.7071C15.1834 19.0976 15.8166 19.0976 16.2071 18.7071C16.5976 18.3166 16.5976 17.6834 16.2071 17.2929L14.3896 15.4754C14.7747 14.9132 15 14.2329 15 13.5C15 11.567 13.433 10 11.5 10C9.567 10 8 11.567 8 13.5C8 15.433 9.567 17 11.5 17Z"
                        />
                      </svg>
                    </button>
                    <div className="w-full grid grid-cols-2 justify-items-center">
                      <button
                        onClick={() => handleAddSolicitud(expe.cod_exp)}
                        className="w-[25px]"
                      >
                        <svg
                          width="16"
                          height="23"
                          viewBox="0 0 16 23"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2 6.57143V18.4898C13.2 19.0449 12.7429 19.502 12.1878 19.502H4.33469C3.77959 19.502 3.32245 19.0449 3.32245 18.4898V6.57143H13.2ZM10.6367 7.89388V18.1469H11.2245V7.89388H10.6367ZM7.97551 7.89388V18.1469H8.56327V7.89388H7.97551ZM5.31429 7.89388V18.1469H5.90204V7.89388H5.31429ZM5.96735 3.81225V2.96327C5.96735 2.42449 6.39184 2 6.93061 2H9.60816C10.1469 2 10.5714 2.42449 10.5714 2.96327V3.81225H13.5265C14.0816 3.81225 14.5388 4.26939 14.5388 4.82449V5.52653H2V4.80816C2 4.25306 2.45714 3.79592 3.01224 3.79592H5.96735V3.81225ZM6.93061 2.65306C6.76735 2.65306 6.63673 2.78367 6.63673 2.94694V3.79592H9.91837V2.94694C9.91837 2.78367 9.78776 2.65306 9.62449 2.65306H6.93061Z"
                            fill="#02518C"
                          />
                        </svg>
                      </button>
                      <button
                        disabled={expe.condicion !== "Ingresado" ? false : true}
                        onClick={() => handleCheckExpe(expe.cod_exp)}
                        className="w-[25px]"
                      >
                        <img
                          src={`${process.env.PUBLIC_URL}/icons/icons_check_expediente.svg`}
                          loading="lazy"
                          className="w-6 h-6 text-primary-800"
                        />
                      </button>
                    </div>
                  </div>
                  <div className=" w-10/12 p-2 text-primary-800">
                    <p className="text-[12px] font-bold">{expe.cod_exp} </p>
                    <p className="text-[12px] font-bold">
                      SOCIO: <span className="font-light">{expe.nom_ape}</span>
                    </p>
                    <p className="text-[12px] font-bold">
                      DNI SOCIO:{" "}
                      <span className="font-light">{expe.dni_socio}</span>
                    </p>
                    <p className="text-[12px] font-bold">
                      FECHA INGRESO:{" "}
                      <span className="font-light">{expe.fecha_in}</span>{" "}
                    </p>
                    <p className="text-[12px] font-bold">
                      FECHA REVISION:{" "}
                      <span className="font-light">{expe.fecha_re}</span>{" "}
                    </p>
                    <div className="text-[12px] font-bold inline-flex">
                    {expe.condicion === "REVISADO" ? (
                      <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>
                        REVISADO
                      </div>
                    ) : expe.condicion === "PROCESO DE ELIMINACIÓN" ? (
                      <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-yellow-500 me-2"></div>
                        EN PROCESO DE ELIMINACIÓN
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>
                        SIN REVISIÓN
                      </div>
                    )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <ModalAddExpe
              cod="EXP-"
              title={"NUEVO EXPEDIENTE"}
              description={"La informacion"}
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              user={selectedUser}
              showToast={showToast}
            />
            <ModalSolicitud
              asunto="ELIMINACIÓN"
              enviar={"ADMINISTRADOR"}
              isOpen={isModalOpenSolicitud}
              onClose={handleCloseModalSoli}
              expe={selectedExpe}
              showToast={showToast}
            />

            <ModalCheckExpe
              isOpen={isModalCheckExpe}
              onClose={handleCloseModalCheckExpe}
              chec_expe={selectedCheckExpe}
            />

            <ModaSinRegistros
              isOpen={isModalSinRegistroOpen}
              onClose={() => setIsModalSinRegistroOpen(false)}
              message={errorMessage}
            />
            <Modal_ver_pdf
              isOpen={modalIsOpenPDF}
              onClose={handlecloseModalPDF}
              url={urlPdf}
            />
          </div>

          <ToastContainer />
        </>
      )}
    </>
  );
}
